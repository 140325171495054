export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
        "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zimmer"])},
        "object_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekttyp"])},
        "object_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektart"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
        "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieten"])},
        "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufen"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lädt..."])},
        "real_estate_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobiliensuche"])},
        "no_search_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leider gibt es keinen Suchtreffer"])},
        "estate_type": {
          "commercial_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommerzielle Immobilie"])},
            "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
            "commercial_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude"])},
            "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft"])},
            "hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halle"])},
            "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal"])},
            "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Büro"])},
            "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrieb"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes"])},
            "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
            "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazin"])}
          },
          "usage_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück"])},
            "agricultural_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landwirtschaftlicher Grund"])},
            "forest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wald"])},
            "building_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baugrund"])},
            "commercial_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewerbegrundstück"])},
            "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück"])}
          },
          "residential_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnimmobilie"])},
            "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnung"])},
            "residential_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude"])},
            "farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hof"])},
            "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
            "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haus"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes"])},
            "row_house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenhaus"])},
            "villa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villa"])}
          },
          "unimportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal / Alle"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannt / Nicht Angegeben"])}
        }
      },
      "en": {
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
        "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Room"])},
        "object_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object type"])},
        "object_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object category"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
        "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent"])},
        "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading..."])},
        "real_estate_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
        "no_search_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unfortunately, there is no search result"])},
        "estate_type": {
          "commercial_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial property"])},
            "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
            "commercial_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building"])},
            "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
            "hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hall"])},
            "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not important"])},
            "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office"])},
            "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
            "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
            "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse"])}
          },
          "usage_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
            "agricultural_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agricultural land"])},
            "forest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forest"])},
            "building_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction land"])},
            "commercial_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial land"])},
            "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])}
          },
          "residential_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residential property"])},
            "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnung"])},
            "residential_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude"])},
            "farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hof"])},
            "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
            "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not important"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
            "row_house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row house"])},
            "villa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villa"])}
          },
          "unimportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not important / All"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown / Not Specified"])}
        }
      },
      "it": {
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo"])},
        "room": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera"])},
        "object_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di proprietà"])},
        "object_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oggetto in vendita"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca"])},
        "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affitto"])},
        "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendita"])},
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento..."])},
        "real_estate_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricerca immobiliare"])},
        "no_search_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun risultato"])},
        "estate_type": {
          "commercial_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobile commerciale"])},
            "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
            "commercial_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificio commerciale"])},
            "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negozio"])},
            "hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capannone"])},
            "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indifferente"])},
            "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ufficio"])},
            "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
            "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ristorante"])},
            "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazzino"])}
          },
          "usage_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terreno"])},
            "agricultural_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terreno agricolo"])},
            "forest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foresta"])},
            "building_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terreno edificabile"])},
            "commercial_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terreno commerciale"])},
            "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terreno"])}
          },
          "residential_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobile residenziale"])},
            "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appartamento"])},
            "residential_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificio"])},
            "farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maso"])},
            "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
            "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indifferente"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
            "row_house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa a schiera"])},
            "villa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villa"])}
          },
          "unimportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indifferente / Tutti"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconosciuto / Non definito"])}
        }
      }
    }
  })
}
