export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "de": {
        "real_estate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilie"])},
        "back_to_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Übersicht"])},
        "non_binding_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unverbindlich Anfragen"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
        "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mehr erfahren"])},
        "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anfragen"])},
        "on_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis auf Anfrage"])},
        "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mieten"])},
        "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf"])},
        "object_rent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " zu vermieten"])},
        "object_buy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " zu verkaufen"])},
        "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
        "extra_price": {
          "single_garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelgarage"])},
          "double_garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppelgarage"])},
          "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
          "carport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carport"])},
          "exterior_parking_lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Außenstellplatz"])},
          "cellar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keller"])},
          "garden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garten"])}
        },
        "estate_type": {
          "commercial_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommerzielle Immobilie"])},
            "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
            "commercial_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude"])},
            "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäft"])},
            "hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Halle"])},
            "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal"])},
            "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Büro"])},
            "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betrieb"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes"])},
            "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
            "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazin"])}
          },
          "usage_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstücksart"])},
            "agricultural_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Landwirtschaftlicher Grund"])},
            "forest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wald"])},
            "building_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baugrund"])},
            "commercial_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewerbegrundstück"])},
            "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundstück"])}
          },
          "residential_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnimmobilie"])},
            "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnung"])},
            "residential_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude"])},
            "farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hof"])},
            "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
            "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haus"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes"])},
            "row_house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reihenhaus"])},
            "villa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villa"])}
          },
          "unimportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal / Alle"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannt / Nicht Angegeben"])}
        },
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
        "object_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektinformationen"])},
        "reference_code": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ref Nr. ", _interpolate(_list(0))])},
        "all_images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Bilder"])},
        "list": {
          "commercial_surface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handelsfläche"])},
          "net_surface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettofläche"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis"])},
          "estate_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zustand"])},
          "rooms_bath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badezimmer"])},
          "rooms_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlafzimmer"])},
          "year_built": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baujahr"])},
          "heating_control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heizungskontrolle"])},
          "heating_energy_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heizungsart"])},
          "heating_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heizungssystem"])},
          "condominium_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kondominumskosten"])},
          "has_garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Garage"])},
          "has_car_parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Stellplatz"])},
          "has_cellar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Keller"])},
          "has_balcony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Balkon"])},
          "has_terrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Terasse"])},
          "has_garden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Garten"])},
          "has_pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Schwimmbad"])},
          "has_elevator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Aufzug"])},
          "is_furnished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einrichtung"])},
          "is_rented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist vermietet"])},
          "energy_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energiezertifikat"])},
          "floor_meadow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiesenfläche"])},
          "floor_forest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waldfläche"])},
          "reference_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenznummer"])}
        },
        "energy_efficiency": {
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannt / Nicht Angegeben"])},
          "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Ausarbeitung"])}
        },
        "estate_condition": {
          "good_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guter Zustand"])},
          "habitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewohnbar"])},
          "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht Angegeben"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu"])},
          "renovated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rennoviert"])},
          "to_be_refurbished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu sanieren"])},
          "to_be_renovated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu rennovieren"])},
          "unimportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Egal / Alle"])},
          "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannt / Nicht Angegeben"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannt / Nicht Angegeben"])},
          "well_maintained": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gepflegt"])}
        },
        "heating_control": {
          "autonomous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autonom"])},
          "central": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zentral"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Heizung"])}
        },
        "heating_energy_source": {
          "electric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektrisch"])},
          "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
          "heatpump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wärmepumpe"])},
          "methan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metangas"])},
          "no_heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Heizung"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannt / Nicht Angegeben"])},
          "oil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Öl"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anderes"])},
          "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fernheizung"])},
          "solar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solarheizung"])},
          "wood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holz/Pellets"])}
        },
        "heating_system": {
          "air": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luftwechsler"])},
          "furnace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kachelofen"])},
          "no_heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Heizung"])},
          "pavement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fußboden"])},
          "radiator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heizkörper"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannt / Nicht Angegeben"])}
        }
      },
      "en": {
        "real_estate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real Estate"])},
        "back_to_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to overview"])},
        "non_binding_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-binding request"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
        "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
        "on_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price on request"])},
        "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rent"])},
        "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase"])},
        "object_rent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Rent a ", _interpolate(_list(0))])},
        "object_buy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Buy a ", _interpolate(_list(0))])},
        "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
        "extra_price": {
          "single_garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single garage"])},
          "double_garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double garage"])},
          "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
          "carport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carport"])},
          "exterior_parking_lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exterior parking lot"])},
          "cellar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cellar"])},
          "garden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garden"])}
        },
        "estate_type": {
          "commercial_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial property"])},
            "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
            "commercial_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building"])},
            "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])},
            "hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hall"])},
            "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
            "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office"])},
            "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
            "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurant"])},
            "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any"])}
          },
          "usage_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type of land"])},
            "agricultural_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agricultural land"])},
            "forest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forest"])},
            "building_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building land"])},
            "commercial_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial land"])},
            "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])}
          },
          "residential_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residential property"])},
            "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartment"])},
            "residential_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Building"])},
            "farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farm"])},
            "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
            "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
            "row_house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Row house"])},
            "villa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villa"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any"])}
          },
          "unimportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any / All"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown / Not specified"])}
        },
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
        "object_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Object information"])},
        "reference_code": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ref No. ", _interpolate(_list(0))])},
        "all_images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All images"])},
        "list": {
          "commercial_surface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial surface"])},
          "net_surface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net surface"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
          "estate_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condition"])},
          "rooms_bath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bathrooms"])},
          "rooms_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrooms"])},
          "year_built": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Year built"])},
          "heating_control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heating control"])},
          "heating_energy_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heating fuel"])},
          "heating_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heating system"])},
          "condominium_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condominium expenses"])},
          "has_garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has garage"])},
          "has_car_parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has parking space"])},
          "has_cellar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has cellar"])},
          "has_balcony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has balcony"])},
          "has_terrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has terrace"])},
          "has_garden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has garden"])},
          "has_pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has pool"])},
          "has_elevator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has elevator"])},
          "is_furnished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Furnished"])},
          "is_rented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is rented"])},
          "energy_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energy certificate"])},
          "floor_meadow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meadow area"])},
          "floor_forest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forest area"])},
          "reference_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference number"])}
        },
        "energy_efficiency": {
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown / Not Specified"])},
          "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Progress"])}
        },
        "estate_condition": {
          "good_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good Condition"])},
          "habitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habitable"])},
          "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not Specified"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New"])},
          "renovated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renovated"])},
          "to_be_refurbished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be Refurbished"])},
          "to_be_renovated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To be Renovated"])},
          "unimportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unimportant / All"])},
          "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown / Not Specified"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown / Not Specified"])},
          "well_maintained": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Well Maintained"])}
        },
        "heating_control": {
          "autonomous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autonomous"])},
          "central": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Heating"])}
        },
        "heating_energy_source": {
          "electric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electric"])},
          "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
          "heatpump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heat Pump"])},
          "methan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Methane Gas"])},
          "no_heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Heating"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown / Not Specified"])},
          "oil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oil"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
          "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remote Heating"])},
          "solar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solar Heating"])},
          "wood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wood/Pellets"])}
        },
        "heating_system": {
          "air": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Air Exchanger"])},
          "furnace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tile Stove"])},
          "no_heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Heating"])},
          "pavement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Floor"])},
          "radiator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiator"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown / Not Specified"])}
        }
      },
      "it": {
        "real_estate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilie"])},
        "back_to_overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna all'elenco"])},
        "non_binding_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta senza impegno"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descrizione"])},
        "learn_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scopri di più"])},
        "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiedi"])},
        "on_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo su richiesta"])},
        "rent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affitto"])},
        "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendita"])},
        "object_rent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " in affitto"])},
        "object_buy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " in vendita"])},
        "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra"])},
        "extra_price": {
          "single_garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Box auto singolo"])},
          "double_garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Box auto doppio"])},
          "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Box auto"])},
          "carport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carport"])},
          "exterior_parking_lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcheggio esterno"])},
          "cellar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantina"])},
          "garden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giardino"])}
        },
        "estate_type": {
          "commercial_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobile commerciale"])},
            "bar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bar"])},
            "commercial_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificio commerciale"])},
            "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Negozio"])},
            "hall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capannone"])},
            "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indifferente"])},
            "office": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ufficio"])},
            "operation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attività"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
            "restaurant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ristorante"])},
            "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazzino"])}
          },
          "usage_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipologia di terreno"])},
            "agricultural_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terreno agricolo"])},
            "forest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foresta"])},
            "building_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terreno edificabile"])},
            "commercial_land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terreno commerciale"])},
            "land": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terreno"])}
          },
          "residential_real_estate_type": {
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobile residenziale"])},
            "apartment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appartamento"])},
            "residential_building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edificio"])},
            "farm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maso"])},
            "garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
            "house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa"])},
            "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indifferente"])},
            "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
            "row_house": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casa a schiera"])},
            "villa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Villa"])}
          },
          "unimportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non importa / Tutti"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconosciuto / Non definito"])}
        },
        "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])},
        "object_information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni"])},
        "reference_code": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Nr. Rif. ", _interpolate(_list(0))])},
        "all_images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galleria"])},
        "list": {
          "commercial_surface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superficie commerciale"])},
          "net_surface": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superficie netta"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezzo"])},
          "estate_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
          "rooms_bath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bagno"])},
          "rooms_bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camere da letto"])},
          "year_built": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anno di costruzione"])},
          "heating_control": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alimentazione riscaldamento"])},
          "heating_energy_source": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo riscaldamento"])},
          "heating_system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo impianto riscaldamento"])},
          "condominium_expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spese mensili"])},
          "has_garage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage"])},
          "has_car_parking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcheggio esterno"])},
          "has_cellar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantina"])},
          "has_balcony": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balcone"])},
          "has_terrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terrazzo"])},
          "has_garden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giardino"])},
          "has_pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piscina"])},
          "has_elevator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascensore"])},
          "is_furnished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arredato"])},
          "is_rented": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affittato"])},
          "energy_efficiency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe energetica"])},
          "floor_meadow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superficie prato"])},
          "floor_forest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Superficie bosco"])},
          "reference_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nr. Rif."])}
        },
        "energy_efficiency": {
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconosciuto / Non definita"])},
          "waiting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In elaborazione"])}
        },
        "estate_condition": {
          "good_condition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buono stato"])},
          "habitable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abitabile"])},
          "nd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non definito"])},
          "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo"])},
          "renovated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ristrutturato"])},
          "to_be_refurbished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da risanare"])},
          "to_be_renovated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da ristrutturare"])},
          "unimportant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non importa / tutti"])},
          "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuno / Non definito"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconosciuto / Non definito"])},
          "well_maintained": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ben mantenuto"])}
        },
        "heating_control": {
          "autonomous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autonomo"])},
          "central": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centralizzato"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun riscaldamento"])}
        },
        "heating_energy_source": {
          "electric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elettrico"])},
          "gas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas"])},
          "heatpump": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pompa di calore"])},
          "methan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas metano"])},
          "no_heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun riscaldamento"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconosciuto / Non definito"])},
          "oil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasolio"])},
          "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altro"])},
          "remote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teleriscaldameno"])},
          "solar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solare"])},
          "wood": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legna/pellet"])}
        },
        "heating_system": {
          "air": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Climatizzatore"])},
          "furnace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stufa"])},
          "no_heating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun riscaldamento"])},
          "pavement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A pavimento"])},
          "radiator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radiatori"])},
          "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sconosciuto / Non definito"])}
        }
      }
    }
  })
}
