<template>
  <div class="emma-widget">
    <div class="emma-widget__menu">
      <div class="emma-widget__menu-toggle" v-if="viewportWidth < 480">
        <span>{{ $t('real_estate_search') }}</span><span class="emma-icon-search"></span>
        <span class="emma-icon-arrow-down" v-bind:class="{'emma-icon-arrow-down--rotate': showMenu}"
              v-on:click="showMenu = !showMenu"></span>
      </div>
      <div class="emma-widget__wrapper" v-if="viewportWidth > 481 || showMenu">

        <div>
          <field-location-search v-model="geoLocation"/>

        </div>
        <div>
          <field-selected-item v-on:delete-selection="geoLocation = false" v-if="selectedLocationString"
                               v-bind:content="selectedLocationString"/>
        </div>
        <div>
          <field-choice v-bind:options="[{value: 'buy', label: $t('buy')},{value: 'rent', label: $t('rent')}]"
                        v-model="pricing"/>
        </div>


        <div>
          <field-select v-bind:label="$t('object_category')" v-bind:options="objectCategoryOptions"
                        v-model="objectCategory"/>
        </div>
        <div>
          <field-select v-bind:label="$t('object_type')" v-bind:options="objectTypeOptions"
                        v-model="objectType"/>
        </div>


        <div>
          <field-range v-bind:label="$t('price')" v-model:from="priceFrom" v-model:to="priceTo"/>
        </div>
        <div>
          <field-range
              v-bind:label="$t('room')"
              v-bind:active="objectCategory === 'residential_real_estate'"
              v-model:from="roomsFrom"
              v-model:to="roomsTo"
          />
        </div>
        <div>
          <button-big v-bind:disabled="isOnDefaultValues" v-on:click="setToDefault()"/>
        </div>

      </div>
    </div>
    <div class="emma-widget__body">
      <div class="emma-widget__status" v-if="loading">
        <loading-item v-bind:label="$t('loading')"/>
      </div>
      <template v-else>
        <div class="emma-widget__status" v-if="realEstateItems.length === 0">
          <div class="emma-widget__status-empty">
            <span>{{ $t('no_search_result') }}</span>
          </div>
        </div>
        <div class="emma-widget__body-wrapper" v-else>

          <real-estate-item
              v-for="item in realEstateItems"
              v-bind:item="item"
              v-bind:key="item.uuid"

              v-on:select-item="selectItem(item, false)"
              v-on:select-request-item="selectItem(item, true)"
          ></real-estate-item>
        </div>
      </template>

    </div>
    <div class="emma-widget__load-more" ref="loadMore">

    </div>
  </div>
  <popup-detail
      v-if="selectedItem"
      v-bind:request="selectedItemRequest"
      v-bind:item="selectedItem"
      v-on:close-popup="selectedItem = false"
  />
</template>

<script>

import FieldLocationSearch from "@/components/field-location-search.vue";
import FieldChoice from "@/components/field-choice.vue";
import FieldSelect from "@/components/field-select.vue";
import FieldRange from "@/components/field-range.vue";
import ButtonBig from "@/components/button-big.vue";
import FieldSelectedItem from "@/components/field-selected-item.vue";
import axios from "axios";
import {mapState} from "vuex";
import RealEstateItem from "@/components/real-estate-item.vue";
import LoadingItem from "@/components/loading-item.vue";
import PopupDetail from "@/components/popup-detail.vue";

export default {
  name: 'App',
  components: {
    PopupDetail,
    LoadingItem,
    RealEstateItem, FieldSelectedItem, ButtonBig, FieldRange, FieldSelect, FieldChoice, FieldLocationSearch
  },
  data() {
    return {
      realEstateItems: [],
      realEstateTotalItems: 0,
      loading: false,
      backgroundLoading: false,

      geoLocation: false,
      objectCategory: "",
      objectType: "",
      pricing: "",

      priceFrom: "",
      priceTo: "",

      roomsFrom: "",
      roomsTo: "",

      selectedItemRequest: false,
      selectedItem: false,

      showMenu: false,

      page: 1,
    }
  },
  computed: {
    isOnDefaultValues() {
      return this.geoLocation === false &&
          this.objectCategory === "" &&
          this.objectType === "" &&
          this.pricing === "" &&

          this.priceFrom === "" &&
          this.priceTo === "" &&

          this.roomsFrom === "" &&
          this.roomsTo === "";
    },
    ...mapState([
      'token', 'channel', 'summary', 'apiHost'
    ]),
    objectCategoryOptions() {
      let result = [];
      for (let x in this.summary.itemCount) {
        if (this.summary.itemCount[x] > 0 && x !== 'total' && x != null) {
          result.push({
            value: x,
            label: this.$t('estate_type.' + (x !== 'land' ? x : 'usage') + '_type.category')
          });
        }
      }
      return result;
    },
    objectTypeOptions() {
      let result = [];
      console.log(this.summary.categories);
      for (let x in this.summary.categories) {
        this.summary.categories[x].forEach(y => {
          if(y != null) {
            result.push({
              category: this.$t('estate_type.' + x + '.category'),
              label: this.$t('estate_type.' + x + '.' + y),
              value: y
            });
          }

        })
      }

      return result;
    },
    categoryType() {
      let result = {};
      for (let x in this.summary.categories) {
        this.summary.categories[x].forEach(y => {
          result[y] = x.replace('_type', '').replace('usage', 'land');
        });
      }

      return result;
    },
    selectedLocationString() {
      if (this.geoLocation) {
        let lang = this.$i18n.locale.toUpperCase();
        let geolocation = this.geoLocation.localizations?.find((x) => (x.language === lang)) || this.geoLocation.localizations?.find((x) => (x.language === 'EN')) || this.geoLocation.localizations[0];

        return geolocation.municipality;
      } else {
        return false;
      }

    },
    viewportWidth() {
      return window.innerWidth;
    },
    filterParams() {
      let params = {};

      if (this.objectType) {
        params[this.categoryType[this.objectType].replace('real_', '') + "_type"] = this.objectType;
        params['estate_category'] = this.categoryType[this.objectType];
      }

      if (this.geoLocation)
        params['location'] = this.geoLocation?.uuid;

      if (this.priceFrom)
        params['price_from'] = this.priceFrom;
      if (this.priceTo)
        params['price_to'] = this.priceTo;

      let bedroomsAmount = [];
      for (let i = this.roomsFrom; i <= this.roomsTo; i++) {
        bedroomsAmount.push(i);
      }

      if (this.roomsFrom)
        params['bedrooms'] = bedroomsAmount.join(',');

      if (this.pricing)
        params['pricing'] = this.pricing;


      return params;
    },
    params() {
      let params = {
        estate_category: this.objectCategory || 'real_estate',
        page: this.page,
        ...this.filterParams
      };


      params['order[createdAt]'] = 'DESC';

      return params;

    }
  },
  watch: {
    objectCategory(val) {
      if (this.categoryType[this.objectType] !== val)
        this.objectType = "";
    },
    objectType(val) {
      if (val !== "")
        this.objectCategory = (this.categoryType[val] !== 'usage' ? this.categoryType[val] : 'land');
    },
    filterParams() {
      this.getRealEstateItems();
    }
  },
  methods: {
    setToDefault() {
      this.geoLocation = false;
      this.objectCategory = "";
      this.objectType = "";
      this.pricing = "";

      this.priceFrom = "";
      this.priceTo = "";

      this.roomsFrom = "";
      this.roomsTo = "";
    },
    async getSummary() {
      this.loading = true;
      let response = await axios.get(`https://${this.apiHost}/api/v1/channels/${this.channel}/summary`, {
        headers: {
          'authorization': 'Bearer ' + this.token
        }
      });

      this.$store.commit('setSummary', response.data);
      this.loading = false;
    },
    async getRealEstateItems() {
      console.log("get real estate items");
      this.page = 1;
      this.loading = true;
      let response = await axios.get(`https://${this.apiHost}/api/v1/channels/${this.channel}/channel_publications`, {
        headers: {
          'authorization': 'Bearer ' + this.token
        },
        params: this.params
      });

      this.realEstateItems = response.data['hydra:member'];
      this.realEstateTotalItems = response.data['hydra:totalItems'];
      this.loading = false;
    },
    async addRealEstateItems() {
      console.log("add real estate items");
      this.backgroundLoading = true;
      let response = await axios.get(`https://${this.apiHost}/api/v1/channels/${this.channel}/channel_publications`, {
        headers: {
          'authorization': 'Bearer ' + this.token
        },
        params: this.params
      });

      this.realEstateItems = [...this.realEstateItems, ...response.data['hydra:member']];
      this.realEstateTotalItems = response.data['hydra:totalItems'];
      this.backgroundLoading = false;
    },
    async selectItem(item, request) {
      this.selectedItemRequest = request;
      this.selectedItem = item;
    },
    checkPassedParameters() {
      let values = location.hash.replace('#', '').split('&');
      values.forEach((x) => {
        let key = x.split('=')[0];
        let value = x.split('=')[1];

        if (key === 'publication') {
          this.selectedItem = this.realEstateItems.find(x => x.uuid === value) ?? false;
        } else if (key === 'request') {
          this.selectedItemRequest = this.realEstateItems.find(x => x.uuid === value) ?? false;
        }
      });

    },
    addItems() {
      if(this.realEstateItems.length !== 0 && this.realEstateItems.length < this.realEstateTotalItems && !this.loading && !this.backgroundLoading) {
        this.page++;
        this.addRealEstateItems();
      }
    }
  },
  async mounted() {
    await this.getSummary();
    await this.getRealEstateItems();
    this.checkPassedParameters();
    window.addEventListener('scroll', () => {
      if(this.$refs.loadMore) {
        if(document.documentElement.scrollTop + window.innerHeight > this.$refs.loadMore.offsetTop) {
          console.log("test");
          this.addItems();
        }
      }
    });
  }
}
</script>

<style lang="scss">
@import "style/fonts.scss";

#emma-widget, .emma-popup-detail, .emma-popup-images {
  font-size: 10px;
  color: var(--text-color);
  margin: 0;
}

:root {

  --color-dark: #707070;
  --color-dark-text: #fff;
  --color-highlighted: #B2B2B2;
  --color-highlighted-text: #000;
  --border-color: var(--color-dark);
  --button-color: #D0D0D0;
  --button-color-text: #000;
  --accent-color: #E2E2E2;
  --button-item-selected: var(--color-highlighted);
  --text-color: #000000;
  --accent-background-color: #B2B2B2;

  --border-radius: 1.2em;
}


@media screen and (max-width: 1440px) and (min-width: 769px) {

  #emma-widget {
    font-size: 9px;
  }
}

.emma-widget__menu-toggle {
  padding: 0 3em;
  height: 10em;
  display: flex;
  align-items: center;

  .emma-icon-arrow-down {
    padding: 1em;
    padding-right: 0;
    margin-left: 0.5em;

    &::before {

      transition: transform 0.3s;
      display: block;
    }

    &.emma-icon-arrow-down--rotate {
      &::before {
        transform: rotate(180deg);
      }
    }

    &::before {
      font-size: 2.4em;
    }
  }

  .emma-icon-search {
    margin-left: auto;

    &::before {
      font-size: 2em;
    }
  }

  :nth-child(1) {
    font-size: 1.8em;
    font-weight: bold;
  }

}


.emma-widget {
  &__status {
    display: flex;
    align-items: center;
    max-width: 140em;
    margin: 0 auto;
    padding-bottom: 2em;
    justify-content: center;
  }

  &__status-empty {
    color: orangered;

    span {
      font-size: 1.6em;
    }
  }

  &__body {
    padding: 6em 1em 0;
  }

  &__menu {
    padding-bottom: 3.5em;
    border-bottom: 1px solid var(--border-color);
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1.5em;
    grid-template-rows: repeat(3, 1fr);
    max-width: 140em;
    margin: 0 auto;
    padding: 0 1em;
  }

  &__body-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5em;
    row-gap: 4em;
    max-width: 140em;
    margin: 0 auto;
  }
}


.emma-field {

  &.emma-field--error {

    .emma-field__error-text span {
      font-size: 1.3em;
    }

    .emma-field__error-text {
      margin: 0.3em 0.5em 0;
      color: orangered;
    }

    .emma-field__wrapper {
      border-color: orangered;
    }
  }

  &__wrapper {
    border: 1px solid var(--border-color);
    border-radius: var(--border-radius);
    min-height: 3.6em;
    display: flex;
    align-items: center;
    background-color: white;
    overflow: hidden;
    position: relative;

    &--readonly {
      background-color: #f6f6f6;
      color: #b6b6b6;
      border-color: #b6b6b6;
    }

    input, select, textarea {
      width: 100%;
      background-color: transparent;
      border: transparent;
      font-size: 1.4em;
      padding: 0 1em;
      font-family: inherit;
      height: 100%;
      outline: none;
      resize: none;
    }

    textarea {
      height: 7em;
      padding: 0.5em 1em;
    }
  }

  &__label {
    margin: 0 1.6em 0.8em 1.6em;

    span {
      font-size: 1.4em;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}

@media screen and (min-width: 481px) {
  .emma-widget__menu {

    .emma-widget__wrapper {

      > :nth-child(1) {
        grid-column: 2 / 7;
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: end;
      }

      > :nth-child(2) {
        grid-column: 7 / 9;
      }

      > :nth-child(3) {
        grid-column: 9 / 11;
      }

      > :nth-child(4) {
        grid-column: 2 / 6;
      }

      > :nth-child(5) {
        grid-column: 7 / 11;
      }


      > :nth-child(6) {
        grid-column: 2 / 6;
      }

      > :nth-child(7) {
        grid-column: 7 / 11;
      }

      > :nth-child(8) {
        grid-column: 11 / 13;
        grid-row: 3;
      }
    }

  }
}

@media screen and (max-width: 768px) {
  .emma-widget__body-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 480px) {


  .emma-widget__wrapper {
    grid-template-columns: repeat(2, 1fr);
    padding: 4em 3em;
  }
  .emma-widget__body-wrapper {
    grid-template-columns: 1fr;
    gap: 8em
  }
  .emma-widget__body {
    padding: 4em 3em;
  }

  .emma-widget__menu {
    padding-bottom: 0;

    .emma-widget__wrapper {
      padding: 2em 3em;


      > div {
        display: flex;
        flex-direction: column;
        justify-content: end;
      }

      > :nth-child(1) {
        grid-column: 1 / 3;
      }

      > :nth-child(2) {
        grid-column: 1 / 2;
      }

      > :nth-child(3) {
        grid-column: 2 / 3;
      }

      > :nth-child(4) {
        grid-column: 1 / 3;
      }

      > :nth-child(5) {
        grid-column: 1 / 3;
      }


      > :nth-child(6) {
        grid-column: 1 / 3;
      }

      > :nth-child(7) {
        grid-column: 1 / 3;
      }

      > :nth-child(8) {
        grid-column: 1 / 3;
      }
    }

  }
}

@media screen and (max-width: 375px) {
  .emma-widget__body {
    padding: 3em 1.5em;
  }

  .emma-widget__menu {

    .emma-widget__wrapper {
      padding: 3em 1.5em;
    }
  }
}

</style>

<i18n>
{
  "de": {
    "price": "Preis",
    "room": "Zimmer",
    "object_type": "Objekttyp",
    "object_category": "Objektart",
    "search": "Suchen",
    "rent": "Mieten",
    "buy": "Kaufen",
    "loading": "Lädt...",
    "real_estate_search": "Immobiliensuche",
    "no_search_result": "Leider gibt es keinen Suchtreffer",
    "estate_type": {
      "commercial_real_estate_type": {
        "category": "Kommerzielle Immobilie",
        "bar": "Bar",
        "commercial_building": "Gebäude",
        "business": "Geschäft",
        "hall": "Halle",
        "hotel": "Hotel",
        "nd": "Egal",
        "office": "Büro",
        "operation": "Betrieb",
        "other": "Anderes",
        "restaurant": "Restaurant",
        "warehouse": "Magazin"
      },
      "usage_type": {
        "category": "Grundstück",
        "agricultural_land": "Landwirtschaftlicher Grund",
        "forest": "Wald",
        "building_land": "Baugrund",
        "commercial_land": "Gewerbegrundstück",
        "land": "Grundstück"
      },
      "residential_real_estate_type": {
        "category": "Wohnimmobilie",
        "apartment": "Wohnung",
        "residential_building": "Gebäude",
        "farm": "Hof",
        "garage": "Garage",
        "house": "Haus",
        "nd": "Egal",
        "other": "Anderes",
        "row_house": "Reihenhaus",
        "villa": "Villa"
      },
      "unimportant": "Egal / Alle",
      "unknown": "Unbekannt / Nicht Angegeben"
    }
  },
  "en": {
    "price": "Price",
    "room": "Room",
    "object_type": "Object type",
    "object_category": "Object category",
    "search": "Search",
    "rent": "Rent",
    "buy": "Buy",
    "loading": "Loading...",
    "real_estate_search": "Search",
    "no_search_result": "Unfortunately, there is no search result",
    "estate_type": {
      "commercial_real_estate_type": {
        "category": "Commercial property",
        "bar": "Bar",
        "commercial_building": "Building",
        "business": "Business",
        "hall": "Hall",
        "hotel": "Hotel",
        "nd": "Not important",
        "office": "Office",
        "operation": "Operation",
        "other": "Other",
        "restaurant": "Restaurant",
        "warehouse": "Warehouse"
      },
      "usage_type": {
        "category": "Land",
        "agricultural_land": "Agricultural land",
        "forest": "Forest",
        "building_land": "Construction land",
        "commercial_land": "Commercial land",
        "land": "Land"
      },
      "residential_real_estate_type": {
        "category": "Residential property",
        "apartment": "Wohnung",
        "residential_building": "Gebäude",
        "farm": "Hof",
        "garage": "Garage",
        "house": "House",
        "nd": "Not important",
        "other": "Other",
        "row_house": "Row house",
        "villa": "Villa"
      },
      "unimportant": "Not important / All",
      "unknown": "Unknown / Not Specified"
    }
  },
  "it": {
    "price": "Prezzo",
    "room": "Camera",
    "object_type": "Tipo di proprietà",
    "object_category": "Oggetto in vendita",
    "search": "Cerca",
    "rent": "Affitto",
    "buy": "Vendita",
    "loading": "Caricamento...",
    "real_estate_search": "Ricerca immobiliare",
    "no_search_result": "Nessun risultato",
    "estate_type": {
      "commercial_real_estate_type": {
        "category": "Immobile commerciale",
        "bar": "Bar",
        "commercial_building": "Edificio commerciale",
        "business": "Negozio",
        "hall": "Capannone",
        "hotel": "Hotel",
        "nd": "Indifferente",
        "office": "Ufficio",
        "operation": "Attività",
        "other": "Altro",
        "restaurant": "Ristorante",
        "warehouse": "Magazzino"
      },
      "usage_type": {
        "category": "Terreno",
        "agricultural_land": "Terreno agricolo",
        "forest": "Foresta",
        "building_land": "Terreno edificabile",
        "commercial_land": "Terreno commerciale",
        "land": "Terreno"
      },
      "residential_real_estate_type": {
        "category": "Immobile residenziale",
        "apartment": "Appartamento",
        "residential_building": "Edificio",
        "farm": "Maso",
        "garage": "Garage",
        "house": "Casa",
        "nd": "Indifferente",
        "other": "Altro",
        "row_house": "Casa a schiera",
        "villa": "Villa"
      },
      "unimportant": "Indifferente / Tutti",
      "unknown": "Sconosciuto / Non definito"
    }
  }
}
</i18n>
